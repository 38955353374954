import { apiAxiosV1 } from '@/configs/axios';
import { StorageService } from './StorageService';
import { AndroidService } from './Android';

export const AuthService = {
  async handleGooglelogin(code) {
    const url = `/user/google_auth/login`;
    const res = await apiAxiosV1.post(url, { code });
    return res?.data;
  },

  async handleGoogleSignup({ code, referralCode, utmSource, fullName }) {
    const url = `/user/google_auth/signup`;
    const payload = {
      code,
      full_name: fullName,
      utm_source: utmSource,
      referral_code: referralCode,

      gaid: AndroidService.getDeviceIdentifiers().ga_id,
    };
    const res = await apiAxiosV1.post(url, payload);
    return res?.data;
  },

  async logout() {
    await AndroidService.signOut();
    StorageService.setAccessToken('');
    StorageService.setAuthRefreshToken('');
    StorageService.setWId('');
    StorageService.setUserId('');
    return;
  },
};
